@import 'setting';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($mainColor, 0.5);
    backdrop-filter: blur(5px);
    display: block;

    .modal-header {
        background-color: $secColor;
        border-color: darken($secColor, 5%);

        h5 {
            color: $mainColor;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 0.8px;
        }
    }

    .modal-content {
        background-color: $secColor;
    }

    .modal-footer {
        border-color: darken($secColor, 5%);

        button {
            background-color: $mainColor;
            width: 120px;
            border-radius: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            color: $secColor;
            height: 44px;
            text-align: center;

            &:hover {
                background-color: darken($mainColor, 8%);
            }

            &.deleteBtn {
                background-color: #952e2e;

                &:hover {
                    background-color: darken(#952e2e, 5%);
                }
            }

            &.cancelBtn {
                background-color: lighten($secColor, 15%);
                color: $mainColor;

                &:hover {
                    background-color: lighten($secColor, 20%);
                }
            }
        }
    }

    form {
        .switchGroup {
            display: block;
        }

        label {
            font-weight: bold;
            letter-spacing: 1px;
            width: 100%;
        }

        input {
            width: 100%;
            padding: 10px;
            border-radius: 12px;
            border: 0;
            background-color: lighten($secColor, 15%);
            color: $mainColor;
            margin-bottom: 20px;
            @extend %ts;

            &:focus {
                box-shadow: none;
                background-color: lighten($secColor, 20%);
            }
        }

        textarea {
            width: 100%;
            padding: 10px;
            border-radius: 12px;
            border: 0;
            background-color: lighten($secColor, 15%);
            color: $mainColor;
            margin-bottom: 20px;
            height: 100px;
            @extend %ts;
            resize: none;

            &:focus {
                box-shadow: none;
                background-color: lighten($secColor, 20%);
            }
        }

        .switch {
            width: 90px;
            height: 44px;
            background-color: lighten($secColor, 15%);
            border-radius: 12px;
            margin-bottom: 0;
            position: relative;
            cursor: pointer;

            @include after {
                cursor: pointer;
                width: 36px;
                height: 36px;
                background-color: darken($secColor, 7%);
                top: 4px;
                border-radius: 12px;
                transform: translateX(4px);
                @extend %ts;
            }
        }

        #privacySwitch {
            display: none;

            &:checked {
                +.switch {
                    background-color: lighten($secColor, 15%);

                    &:after {
                        background-color: $mainColor;
                        transform: translateX(50px);
                    }
                }
            }
        }

        .addBar {
            display: flex;
            padding: 0;
            border: 0;
            background: transparent;

            button {
                background-color: $mainColor;
                width: 90px;
                height: 44px;
                border-radius: 12px;
                margin-left: 10px;
                font-weight: bold;
                color: $secColor;

                &:hover {
                    background-color: darken($mainColor, 8%);
                }
            }
        }

        .memberList {
            display: flex;
            flex-direction: column;
            width: 100%;

            li {
                display: flex;
                border-bottom: 1px solid darken($secColor, 5%);
                padding: 10px 0;

                .item {
                    display: flex;
                    width: 100%;

                    .imgBox {
                        width: 52px;
                        height: 52px;
                        border-radius: 50%;
                        background-color: $mainColor;
                        margin-right: 10px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .textBox {
                        flex-grow: 1;
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .name {
                            margin-bottom: 0;
                            font-size: 18px;
                            font-weight: bold;
                        }
                    }

                    .removeBtn {
                        width: 44px;
                        height: 44px;
                        border-radius: 12px;
                        font-weight: bold;
                        color: $secColor;
                        flex-shrink: 0;

                        span {
                            color: #952e2e;
                            font-size: 28px;
                        }

                        &:hover {
                            background-color: darken($secColor, 5%);
                        }
                    }
                }
            }
        }

        .fileStyle-1 {
            margin-bottom: 10px;
        }
    }
}