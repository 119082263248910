@import "setting";

.channelTypeArea {
    padding: 10px 20px;
    border-bottom: 1px solid lighten($mainColor, 10%);
    @extend %ts;
    display: flex;
    @include media(1024) {
        flex-direction: column;
        max-width: 170px;
        width: 100%;
        display: flex;
        // display: none;
        position: fixed;
        top: 73px;
        left: -170px;
        background-color: $mainColor;
        border-bottom: 0;
        border-right: 1px solid lighten($mainColor, 10%);
        z-index: 2;
        padding: 16px 0;
    }
    &.open {
        display: flex;
        @include media(1024) {
            left: 0;
        }
    }
    ul {
        margin: 0 -10px;
        @include media(1024) {
            display: flex;
            flex-direction: column;
            margin: 0;
            width: 100%;
        }
        li {
            padding: 0 10px;
            @include media(1024) {
                padding: 0;
                display: flex;
                align-items: center;
                border-bottom: 1px solid lighten($mainColor, 10%);
                width: 100%;
            }
            .imgBox {
                display: flex;
                @include center;
                width: 100%;
                height: 100%;
                @include media(1024) {
                    flex-shrink: 0;
                    width: 44px;
                    height: 44px;
                    border-radius: 99px;
                    overflow: hidden;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .btn {
                display: flex;
                @include center;
                padding: 0;
                overflow: hidden;
                @include media(1024) {
                    width: 100%;
                    justify-content: flex-start;
                    padding: 10px;
                    &:hover {
                        background-color: $secColor;
                        .title, span {
                            color: $mainColor;
                        }
                    }
                }
            }
            .title {
                display: none;
                color: $secColor;
                margin-left: 10px;
                @include media(1024) {
                    display: block;
                    margin-top: 6px;
                    margin-bottom: 6px;
                }
            }
        }
        &.channelList {
            margin: 0 10px;
            @include media(1024) {
                margin: 0;
            }
        }
    }
}

.btn {
    background-color: $secColor;
    width: 52px;
    height: 52px;
    border-radius: 999px;
    border: 0;
    cursor: pointer;
    @extend %ts;
    @include media(1024) {
        background: none;
        height: auto;
        text-align: left;
        width: auto;
        padding: 12px 12px;
        border-radius: 0;
    }
    &:hover {
        background-color: darken($secColor, 10%);
    }
    span {
        font-size: 34px;
        color: $mainColor;
        @extend %ts;
        letter-spacing: 0.8px;
        @include media(1024) {
            color: $secColor;
            font-size: 30px;
            width: 44px;
            height: 44px;
            @include center;
        }
    }
    &.home {
        background-color: lighten($secColor, 18%);
        // box-shadow: 0 0 7px $secColor;
        @include media(1024) {
        }
        &:hover {
            background-color: lighten($secColor, 25%);
        }
        .title {
            @include media(1024) {
                color: darken($secColor, 18%);
            }
        }
        span {
            color: darken($secColor, 18%);
        }
    }
}
