@import 'setting';

.view.signup {
    background: no-repeat url(../images/login_bg.jpg) center/cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(720) {
        background: $mainColor;
        padding-bottom: 40px;
    }
    @include media(540) {
        justify-content: flex-start;
    }
    .formBox {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        @include media(540) {
            padding-top: 40px;
        }
        .head {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding-bottom: 10px;
            .back {
                span {
                    color: $secColor;
                    font-size: 30px;
                }
            }
        }
        form {
            input {
                margin-top: 0;
                width: auto;
                flex-grow: 1;
            }
            .fileStyle-1 {
                margin-bottom: 20px;
            }
        }
    }
}