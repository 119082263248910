$mainColor: rgb(38, 49, 51);
$secColor: rgb(64, 153, 165);

%ts {
    transition: all .3s ease-out;
}

%te {
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin defaultFont {
    font-family: 'Lato', 'Noto Sans TC', sans-serif;
}

@mixin en2 {
    font-family: 'Play', sans-serif;
}

@mixin media($w) {
    @if ($w < 0) {
        @media (min-width: (-$w + px))  {
            @content;
        }
    } @else {
        @media (max-width: ($w + px))  {
            @content;
        }
    }
}

@mixin fade($switch: out, $axis: x, $offset: 10, $name: fade) {
    @keyframes #{$name} {
        from {
            @if ($switch == out) {
                opacity: 1;
            } @else {
                opacity: 0;
            }
            @if ($axis == x) {
                transform: translateX(0);
            } @else {
                transform: translateX($offset);
            }
        }
        to {
            @if ($switch == out) {
                opacity: 0;
            } @else {
                opacity: 1;
            }
            @if ($axis == x) {
                transform: translateX($offset);
            } @else {
                transform: translateX(0);
            }
        }
    }
}

@mixin center($type: flex, $axis: all) {
    @if ($type == flex) {
        display: flex;
        @if ($axis == x or $axis == all) {
            justify-content: center;
        }
        @if ($axis == y or $axis == all) {
            align-items: center;
        }
    }
    @if ($type == transform) {
        position: absolute;
        @if ($axis == x) {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            @content;
        }
        @if ($axis == y) {
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
            @content;
        }
        @if ($axis == all) {
            top: 50%;
            bottom: auto;
            left: 50%;
            right: auto;
            transform: translate(-50%, -50%);
            @content;
        }
    }
    @if ($type == margin) {
        margin: 0 auto;
    }
}

@mixin after($content: "", $position: absolute) {
    &::after {
        content: $content;
        position: $position;
        @content;
    }
}

@mixin before($content: "", $position: absolute) {
    &::before {
        content: $content;
        position: $position;
        @content;
    }
}