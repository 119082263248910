@import 'setting';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($mainColor, 0.5);
    backdrop-filter: blur(5px);
    display: block;

    .form-group {
        display: flex;
        align-items: flex-start;
        input {
            margin-bottom: 0;
        }
        .searchBtn {
            width: 60px;
            height: 44px;
            @include center;
            background-color: $mainColor;
            border-radius: 12px;
            margin-left: 10px;
            &:hover {
                background-color: darken($mainColor, 10%);
            }
            span {
                color: $secColor;
            }
        }
    }

    .searchResults {
        padding-top: 10px;
    }

    .searchItem {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid rgba($mainColor, 0.2);
        .link {
            display: flex;
            width: 100%;
        }
        .info {
            flex-grow: 1;
            font-size: 18px;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            min-width: 0;
            color: $mainColor;
            text-align: left;
        }
        .imgBox {
            width: 44px;
            height: 44px;
            border-radius: 99px;
            overflow: hidden;
            margin-right: 10px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .enterBtn {
            width: 44px;
            height: 44px;
            @include center;
            span {
                color: $mainColor;
            }
        }
    }

    .loadingSmall {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        span { 
            font-weight: bold;
            font-size: 20px;
            letter-spacing: 1px;
            margin-left: 15px;
        }
        svg {
            width: 60px;
            height: 60px;
            margin: 0 !important;
        }
    }
}