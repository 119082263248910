@import 'setting';

.view.login {
    background: no-repeat url(../images/login_bg.jpg) center/cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(720) {
        background: $mainColor;
        padding-bottom: 40px;
    }
    .formBox {
        svg {
            width: 24px;
            height: 24px;
            margin-right: 14px;
        }
        .googleLogin {
            background-color: #fff;
            border-radius: 8px;
            height: 46px;
            align-items: center;
            display: flex;
            @extend %ts;
            &:hover {
                background-color: darken(#fff, 10%);
            }
            span {
                color: #3c4043;
                font-size: 16px;
                font-family: 'Roboto', sans-serif;
                // font-weight: bold;
            }
        }
        .line {
            height: 1px;
            width: 100%;
            background-color: lighten($mainColor, 10%);
            @include center;
            margin: 20px 0;
            position: relative;
            span {
                @include center(transform, y);
                font-size: 16px;
                color: $secColor;
                background-color: $mainColor;
                padding: 0 10px;
            }
        }
        input {
            margin-top: 0;
            width: auto;
            flex-grow: 1;
        }
        h1 {
            margin-top: -4px;
        }
        .btnBox {
            a, button {
                flex-grow: 1;
                width: 50%;
            }
        }
    }
}