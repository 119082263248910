@import "setting";

.toolbarArea {
    padding: 14px;
    border-bottom: 1px solid lighten($mainColor, 10%);
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    @include media(1024) {
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $mainColor;
    }
    .left {
        display: flex;
        align-items: center;
        @include media(-1025) {
            display: none;
        }
        .menu {
            margin-right: 20px;
            padding: 0;
        }
    }
    input {
        border-radius: 8px;
        width: 100%;
        // max-width: 240px;
        flex-grow: 1;
        border: 0;
        height: 44px;
        background-color: darken($mainColor, 5%);
        padding: 0 16px;
        font-size: 16px;
        color: $secColor;
        outline: none;
        font-weight: 500;
        appearance: none;
        &::-webkit-search-cancel-button {
            appearance: none;
        }
        &::placeholder {
            color: lighten($mainColor, 15%);
        }
        @include media(1024) {
            display: none;
        }
    }
    .btn {
        background: transparent;
        width: 44px;
        height: 44px;
        border-radius: 99px;
        padding: 0;
        &:hover {
            span {
                color: lighten($secColor, 10%);
            }
        }
        span {
            color: $secColor;
            @extend %ts;
        }
    }
    h2 {
        margin: 0;
        letter-spacing: 1px;
    }
    ul {
        margin: 0 -5px;
        padding-left: 20px;
        li {
            padding: 0px 5px;
            &.member {
                display: none;
                @include media(1024) {
                    display: flex;
                }
            }
            &.search {
                display: none;
                @include media(1024) {
                    display: flex;
                }
            }
        }
    }
}

.activitySpaceArea {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    @include media(1024) {
        position: relative;
    }
    .contentBox {
        flex-grow: 1;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        overflow-y: auto;
        min-height: 0;
        height: calc(var(--vh) - 157px);
        @include media(1024) {
        }
    }
    .msgList {
        display: flex;
        flex-direction: column;
        .item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;
            .title {
                font-size: 16px;
                font-weight: bold;
                color: $secColor;
                margin: 0;
                // margin-top: 2px;
                margin-bottom: 4px;
                line-height: 1.1;
                display: flex;
                align-items: center;
                letter-spacing: 0.8px;
                margin-bottom: 16px;
                .date {
                    font-weight: normal;
                    margin-left: 10px;
                    font-size: 14px;
                }
            }
            .text {
                margin: 0;
                color: $secColor;
                font-size: 18px;
                line-height: 1.5;
                text-align: right;
                margin-bottom: 16px;
                display: flex;
                align-items: flex-end;
                span {
                    padding: 8px;
                    background-color: #1b2324;
                    text-align: left;
                    border-radius: 8px;
                    display: flex;
                    margin-right: 10px;
                }
                time {
                    opacity: 0.8;
                    font-size: 14px;
                    display: inline-block;
                    margin-top: 8px;
                }
            }
            .imgBox {
                width: 44px;
                height: 44px;
                background-color: $secColor;
                border-radius: 999px;
                flex-shrink: 0;
                margin-right: 10px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.inputArea {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    flex-shrink: 0;
    .inputTool {
        position: absolute;
        right: 26px;
        top: 0;
        top: 50%;
        transform: translateY(-50%);
        button {
            border: 0;
            background: transparent;
            width: 44px;
            height: 44px;
            border-radius: 99px;
            cursor: pointer;
            &:hover {
                span {
                    color: lighten($secColor, 10%);
                }
            }
            span {
                @extend %ts;
                color: $secColor;
            }
        }
        li {
            position: relative;
            &.emoji {
                @include after {
                    @extend %ts;
                    background: no-repeat url(../images/triangle.svg) center;
                    bottom: 32px;
                    height: 32px;
                    opacity: 0;
                    pointer-events: none;
                    right: 6px;
                    transform: rotate(180deg);
                    width: 32px;
                    filter: blur(20px);
                }
                &.open {
                    &:after {
                        opacity: 1;
                        pointer-events: auto;
                        filter: blur(0px);
                    }
                    .emojiMenu {
                        opacity: 1;
                        pointer-events: auto;
                        filter: blur(0px);
                    }
                }
            }
            .emojiMenu {
                background-color: $secColor;
                border-radius: 10px;
                bottom: 55px;
                display: flex;
                filter: blur(20px);
                opacity: 0;
                overflow: hidden;
                padding: 10px;
                pointer-events: none;
                position: absolute;
                right: -10px;
                @extend %ts;
            }
            .emojiList {
                display: flex;
                flex-wrap: wrap;
                overflow: auto;
                width: 300px;
                max-height: 300px;
                .item {
                    width: 16.66%;
                    @include center;
                    padding: 6px;
                    box-sizing: border-box;
                    font-size: 20px;
                    aspect-ratio: 1/1;
                    @extend %ts;
                    border-radius: 6px;
                    cursor: pointer;
                    &:hover {
                        background-color: darken($secColor, 5%);
                    }
                }
            }
        }
    }
}
