@import 'setting';

.toastList {
  position: fixed;
  z-index: 999;
  max-width: 300px;
  width: 100%;
  right: 0px;
  top: 20px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  z-index: 1056;
  .toast {
    display: block;
    min-height: 90px;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(darken($mainColor, 20%), .5);
    background-color: $secColor;
    padding: 0 20px;
    // width: 100%;
    flex-grow: 1;
    margin-bottom: 20px;
    &.error {
      background-color: #952e2e;
      .header {
        .title {
          color: #fff;
        }
      }
      .content {
        color: #fff;
      }
      .remove {
        background-color: #952e2e;
        &:hover {
          background-color: darken(#952e2e, 5%);
        }
      }
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
    .content {
      padding-bottom: 20px;
    }
    .remove {
      width: 44px;
      height: 44px;
      border-radius: 999px;
      padding: 0;
      background-color: $secColor;
      margin-right: -20px;
      &:hover {
        background-color: lighten($secColor, 10%);
      }
    }
  }
}