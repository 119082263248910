@import 'setting';

.ChannelInfoArea {
    width: 300px;
    padding: 0 20px;
    box-sizing: border-box;
    border-right: 1px solid lighten($mainColor, 10%);
    flex-shrink: 0;
    padding-top: 20px;
    @include media(1024) {
        display: none;
    }
    .head {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;
        h2 {
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .editBtn {
            flex-shrink: 0;
            @include center;
            width: 40px;
            height: 40px;
            span {
                font-size: 22px;
            }
        }
    }
    p {
        &.type {
            font-size: 16px;
            color: $secColor;
        }
        &.intro {
            font-size: 16px;
            color: $secColor;
            opacity: 0.5;
            margin-bottom: 40px;
        }
    }
    ul {
        // margin: 0 -10px;
        display: flex;
        flex-direction: column;
        li {
            width: 100%;
            margin-bottom: 10px;
        }
        button {
            padding: 10px;
            width: 100%;
            border-radius: 8px;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            @extend %ts;
            &:hover {
                background-color: rgba(lighten($secColor, 10%), .1);
            }
            &.current {
                background-color: rgba(lighten($secColor, 10%), .2);
            }
        }
        .item {
            display: flex;
            width: 100%;
        }
        .imgBox {
            width: 52px;
            height: 52px;
            background-color: $secColor;
            border-radius: 999px;
            flex-shrink: 0;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .textBox {
            color: $secColor;
            padding-left: 10px;
            flex-grow: 1;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            min-width: 0;
            p {
                margin: 0;
                @include defaultFont;
                &.name {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 4px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &.text {
                    @extend %ts;
                    font-size: 14px;
                }
            }
        }
    }
    .channelListBox {
        @include media(1024) {
            display: none;
        }
    }
}